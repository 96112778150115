import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ILink} from '@wix/wixstores-client-core';
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client';

export class PaymentRequestNavigationService {
  private readonly siteStore: SiteStore;
  private continueShoppingLink!: ILink;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  public async load() {
    this.continueShoppingLink = await this.fetchContinueShoppingLink();
  }

  private async fetchContinueShoppingLink(): Promise<ILink> {
    const headlessUrl = getHeadlessUrl({
      query: this.siteStore.location.query,
      baseUrl: this.siteStore.location.baseUrl,
      navParams: {logicalName: NavigationType.ECOM_CONTINUE_BROWSING},
    });

    if (headlessUrl) {
      return this.getExternalLink(headlessUrl);
    }

    return this.siteStore.getHomepageLink();
  }

  private getExternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_top', url}};
  }

  public get continueShoppingUrl() {
    return this.continueShoppingLink.url;
  }
}
