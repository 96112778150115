import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {PaymentRequestNavigationService} from '../../services/paymentRequest/PaymentRequestNavigationService';
import {PaymentRequestNavigationStoreProps} from '../../../types/paymentRequestApp.types';

export class PaymentRequestNavigationStore {
  private readonly navigationService: PaymentRequestNavigationService;
  private readonly siteStore: SiteStore;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;

  constructor({
    navigationService,
    siteStore,
    updateComponent,
    isSSR,
  }: {
    navigationService: PaymentRequestNavigationService;
    siteStore: SiteStore;
    updateComponent: () => void;
    isSSR: boolean;
  }) {
    this.navigationService = navigationService;
    this.siteStore = siteStore;
    this.updateComponent = updateComponent;
    this.isSSR = isSSR;
  }

  private readonly navigateToThankYouPage = () => {};

  public toProps(): PaymentRequestNavigationStoreProps {
    return {
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      navigateToThankYouPage: this.navigateToThankYouPage,
      isSSR: this.isSSR,
    };
  }
}
