import {Status} from '@wix/ambassador-ecom-v1-order-payment-request/types';

export enum PaymentRequestStatus {
  paid = 'OTHER_DEFAULT',
  unpaid = 'UNPAID',
  expired = 'EXPIRED',
}

export class PaymentRequestStatusModel {
  public status: PaymentRequestStatus;
  constructor(status?: Status) {
    this.status = status ? toPaymentRequestStatus(status) : PaymentRequestStatus.unpaid;
  }
}

const toPaymentRequestStatus = (status: Status) => {
  switch (status) {
    case Status.PAID:
      return PaymentRequestStatus.paid;
    case Status.EXPIRED:
      return PaymentRequestStatus.expired;
    case Status.UNPAID:
    case Status.UNKNOWN_STATUS:
    default:
      return PaymentRequestStatus.unpaid;
  }
};
