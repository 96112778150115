import {BaseController} from '@wix/wixstores-client-storefront-sdk';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {toMonitorError} from '../../domain/utils/errorMonitor.utils';
import {PaymentRequestControllerProps} from '../../types/paymentRequestApp.types';
import {PaymentRequestSettingsStore} from '../../domain/stores/paymentRequest/PaymentRequestSettingsStore';
import {PaymentRequestSettingsService} from '../../domain/services/paymentRequest/PaymentRequestSettingsService';
import {PaymentRequestPaymentStore} from '../../domain/stores/paymentRequest/PaymentRequestPaymentStore';
import {PaymentRequestStore} from '../../domain/stores/paymentRequest/PaymentRequestStore';
import {PaymentRequestService} from '../../domain/services/paymentRequest/PaymentRequestService';
import {PaymentRequestNavigationService} from '../../domain/services/paymentRequest/PaymentRequestNavigationService';
import {PaymentRequestNavigationStore} from '../../domain/stores/paymentRequest/PaymentRequestNavigationStore';

export class PaymentRequestController extends BaseController {
  protected paymentRequestStore!: PaymentRequestStore;
  protected paymentRequestSettingsStore!: PaymentRequestSettingsStore;
  protected paymentStore!: PaymentRequestPaymentStore;
  protected navigationStore!: PaymentRequestNavigationStore;
  protected translationPromise!: Promise<Record<string, string>>;

  private initServiceFunctions!: (() => void)[];

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.setStores(controllerParams);
    this.loadLocaleDataSetTranslations(controllerParams);
  }

  private loadLocaleDataSetTranslations(controllerParams: ControllerParams) {
    this.translationPromise = this.fetchLocaleDataSetTranslations(controllerParams);
  }

  private async fetchLocaleDataSetTranslations(controllerParams: ControllerParams) {
    const {language} = controllerParams.flowAPI.translations.config;
    let translations: Record<string, string>;
    try {
      translations = await import(`@wix/locale-dataset-data/resources/translations/messages_${language}.json`);
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(e));
      translations = (await import(
        `@wix/locale-dataset-data/resources/translations/messages_en.json`
      )) as unknown as Record<string, string>;
    }
    return {...controllerParams.flowAPI.translations.all, ...translations};
  }

  private async setLocaleDatasetTranslations() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({_translations: await this.translationPromise});
  }

  private setStores({flowAPI, controllerConfig}: ControllerParams) {
    const siteStore = this.siteStore;
    const isSSR = controllerConfig.wixCodeApi.window.rendering.env === 'backend';
    const updateComponent = this.updateComponent.bind(this);

    const navigationService = new PaymentRequestNavigationService({siteStore});
    const paymentRequestSettingsService = new PaymentRequestSettingsService({
      siteStore,
    });
    const paymentRequestService = new PaymentRequestService({siteStore, flowAPI});

    this.paymentRequestSettingsStore = new PaymentRequestSettingsStore({
      paymentRequestSettingsService,
      siteStore,
    });

    this.paymentStore = new PaymentRequestPaymentStore({
      flowAPI,
      paymentRequestService,
      paymentRequestSettingsService,
      isSSR,
      updateComponent,
    });

    this.paymentRequestStore = new PaymentRequestStore({
      flowAPI,
      siteStore,
      paymentRequestService,
      updateComponent,
    });

    this.navigationStore = new PaymentRequestNavigationStore({
      navigationService,
      siteStore,
      updateComponent,
      isSSR,
    });

    this.initServiceFunctions = [
      async () => {
        await paymentRequestService.init();
      },
      () => paymentRequestSettingsService.init(),
      () => navigationService.load(),
    ];
  }

  private readonly updateComponent = () => {
    const props: PaymentRequestControllerProps = {
      isLoading: false,
      paymentRequestStore: this.paymentRequestStore.toProps(),
      paymentRequestSettingsStore: this.paymentRequestSettingsStore.toProps(),
      paymentStore: this.paymentStore.toProps(),
      navigationStore: this.navigationStore.toProps(),
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps(props);
  };

  public readonly load = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.setProps({isLoading: true, fitToContentHeight: true});
    void this.loadData();
    return Promise.resolve();
  };

  public readonly loadData = async (): Promise<void> => {
    void this.setLocaleDatasetTranslations();
    if (this.siteStore.isSSR()) {
      return;
    }

    try {
      await Promise.all(this.initServiceFunctions.map((fn) => fn()));
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(e, 'PaymentRequestController load'));

      await this.onLoadFailure();
      return;
    }

    this.updateComponent();
  };

  private readonly onLoadFailure = async () => {};

  // eslint-disable-next-line @typescript-eslint/require-await
  public readonly init = async (): Promise<void> => {
    return this.load();
  };

  public getFreeTexts(): string[] {
    return [];
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  /* istanbul ignore next */

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onStyleUpdate(_styleParams: /* eslint-disable @typescript-eslint/ban-types */ {}) {}
}
