import {OrderPaymentRequest} from '@wix/ambassador-ecom-v1-order-payment-request/types';
import {mapNullToUndefined} from '../../utils/mapperHelper.utils';
import {PaymentRequestStatusModel} from './PaymentRequestStatus.model';
import {PaymentRequestPriceModel} from './PaymentRequestPrice.model';

export class PaymentRequestModel {
  public id: string;
  public orderId?: string;
  public orderNumber?: string;
  public status: PaymentRequestStatusModel;
  public total: PaymentRequestPriceModel;
  public currency?: string;
  public paymentGatewayOrderId?: string;
  public title?: string;
  public description?: string;
  public expirationDate?: Date;
  public hasItemsWithLongPrice: boolean;

  constructor(paymentRequest: OrderPaymentRequest) {
    this.id = paymentRequest.id!;
    this.orderId = mapNullToUndefined(paymentRequest.orderId);
    this.orderNumber = mapNullToUndefined(paymentRequest.orderNumber);
    this.status = new PaymentRequestStatusModel(paymentRequest.status);
    this.total = new PaymentRequestPriceModel(paymentRequest.amount);
    this.currency = mapNullToUndefined('USD');
    this.paymentGatewayOrderId = mapNullToUndefined(paymentRequest.paymentDetails?.paymentGatewayOrderId);
    this.title = mapNullToUndefined(paymentRequest.title);
    this.description = mapNullToUndefined(paymentRequest.description);
    this.expirationDate = paymentRequest.expirationDate ?? undefined;
    this.hasItemsWithLongPrice = this.total.formattedAmount.length > 12;
  }
}
